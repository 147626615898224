import React, { useState } from 'react';
import CollectionsList from '../CollectionsList';
import useApi from '../components/useApi';
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';

function CollectionsListView() {
  const [collectionName, setCollectionName] = useState('');
  const { isLoading } = useAuth0();
  const restApi = useApi();

  function handleChange(event) {
    setCollectionName(event.target.value);
  }

  if (isLoading) {
    return (
      <div className="loading">Loading</div>
    )
  }
  return (
    <div className="content-wrapper">
      <div className='content-header'>
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-12">
              <h1>Your Collections</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="content">
        {/* <form onSubmit={handleSubmit}>
          <label>
            Collection name:
            <input type="text" value={collectionName} onChange={handleChange} />
          </label>
          <button type="submit">Create collection</button>
        </form> */}
        <CollectionsList />
        <div className="col-sm-12 justify-content-end d-flex">
          <ol>
            {/* Button to create new collection with a plus icon */}
            <Link to="/collections/create">
              <button type="button" className="btn btn-dark btn-circle shadow"><i className="fas fa-plus"></i></button>
            </Link>
          </ol>
        </div>
      </div>
    </div>
  );

}

export default CollectionsListView;