import React from 'react';
import Navigation from './components/Navigation';

import { BrowserRouter, Route, Routes, Link } from 'react-router-dom';
import Login from './Login'
import Auth0Login from './views/Auth0Login'
import Query from './QueryView'
import CollectionsListView from './views/CollectionsListView'
import CollectionDetailView from './views/CollectionDetailView'
import CollectionCreateView from './views/CollectionCreateView'

import { useAuth0 } from '@auth0/auth0-react';
import Dashboard from './views/Dashboard';
import LogoutButton from "./components/elements/LogoutButton";
import LoginButton from "./components/elements/LoginButton";
import ProtectedRoute from "./components/auth/ProtectedRoute";

const Layout = () => {
    const { isAuthenticated } = useAuth0();
    return (
        <BrowserRouter>
            <nav className="main-header navbar navbar-expand navbar-light">
                <ul className="navbar-nav">
                </ul>
                <ul className="navbar-nav ml-auto">

                    <li className="nav-item">
                        {isAuthenticated &&
                        <LogoutButton />}
                    </li>
                    <li className="nav-item">
                        {!isAuthenticated &&
                        <LoginButton />}
                    </li>

                </ul>


            </nav>
            <Navigation />
            {/* <div className="content-wrapper"> */}
                <Routes>
                    <Route exact path="/" element={<Login />} />
                    <Route element={<ProtectedRoute />}>
                        <Route exact path="/dashboard" element={<Dashboard />} />
                        <Route exact path="/collections" element={<CollectionsListView />} />
                        <Route exact path="/collections/create" element={<CollectionCreateView />} />
                        <Route path="/collections/:collection_id" element={<CollectionDetailView />} />
                        <Route exact path="/query" element={<Query />} />
                    </Route>
                    <Route exact path="/login/auth0" element={<Auth0Login />} />
                    {/* <Route path="/logout" element={<Logout />} /> */}
                </Routes>
            {/* </div> */}
        </BrowserRouter>
    );
};
  

export default Layout;