import React, { useEffect, useState } from 'react';
import Layout from './Layout'
import "admin-lte/dist/css/adminlte.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./components/css/Toast.css";
import "./App.css";
import { useAuth0 } from "@auth0/auth0-react";

function App() {
  const { isLoading, isAuthenticated } = useAuth0();

  document.body.classList.add("layout-fixed");
  document.body.classList.add("sidebar-mini");

  useEffect(() => {
  }, [isLoading, isAuthenticated])

  return (
    <div className="App wrapper">
      <div className="preloader flex-column justify-content-center align-items-center" style={{height: isLoading ? "100%" : "0px"}}>
        <p>Just one second...</p>
        <img src="/preloader-2.gif" alt="AdminLTELogo" height="60" width="60" style={{visibility: isLoading ? "visible" : "hidden"}} />
      </div>
      {/* {!loading && <Layout />} */}
      <Layout />
    </div>
  );
}

export default App;
