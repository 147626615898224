import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="https://dev-urja6adxvj2q5238.us.auth0.com"
      clientId="hnlrXDKKo9P9p2mL9DOGppyC5b2sWb6x"
      authorizationParams={{
        redirect_uri: `${window.location.protocol}//${window.location.host}/login/auth0`,
        audience: "http://localhost:3000"
      }}>
      <App />
    </Auth0Provider>
    <script src="https://cdn.jsdelivr.net/npm/admin-lte@3.1/dist/js/adminlte.min.js"></script>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
