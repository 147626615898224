import React from 'react';
import { Link } from 'react-router-dom';

const Navigation = () => {
  const handleLogout = () => {
    // Make API call to localhost:3001/logout
  };

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4">
      <div className="sidebar os-host os-theme-light os-host-overflow os-host-overflow-y os-host-resize-disabled os-host-scrollbar-horizontal-hidden os-host-transition">
        <nav className="mt-2">
          <ul className="nav nav-pills nav-sidebar nav-child-indent flex-column" data-widget="treeview" role="menu">
            <li className="nav-item"><Link className="nav-link" to="/dashboard"><p>Dashboard</p></Link></li>
            <li className="nav-item"><Link className="nav-link" to="/collections">Collections</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/query">Query</Link></li>
            <li className="nav-item"><Link className="nav-link" to="/logout">Logout</Link></li>
          </ul>
        </nav>
      </div>
    </aside>
      
  );
}

export default Navigation;