import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import S3Image from './S3Image';
import useApi from './useApi';
import Card from './Card';

function LightBoxListTemplate({ image, index, setSelectedIndex }) {
    return (
        <div className="col-md-4 col-4">
            <Card title={index + 1} cardClasses="m-1">
                {image}
            </Card>
        </div>
    )
}

const SimilarImageQueryResultList = ({images}) => {
    const [tempUrls, setTempUrls] = useState([]);
    const { getTempUrls } = useApi();
    const [showImages, setShowImages] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [imagesPerPage] = useState(30);
    useEffect(() => {
        let uuids = []
        let start = (pageNumber - 1) * imagesPerPage;
        let end = start + imagesPerPage;
        let _images = images.slice(start, end);
        for (let i = 0; i < _images.length; i++) {
            let image = _images[i];
            let uuid = image._hit._row_data.unique_filename.split(".")[0]
            uuids.push(uuid)
        }
        getTempUrls(uuids)
        .then((response) => response.json())
        .then((result) => {
            for (let i = 0; i < result.urls.length; i++) {
                let url = result.urls[i];
                if (url == null) {
                    result.urls[i] = "https://via.placeholder.com/150"
                }
                _images[i].url = result.urls[i]
            }
            // setTempUrls(result.urls)
            setShowImages(_images)
        })
    }, [pageNumber, images]);
    
    return (
        <div>
            <div className="row no-gutters">
                {showImages?.map((image, index) => <LightBoxListTemplate key={index} image={<S3Image classes="m-auto d-block" fileId={image._hit._row_data.unique_filename.split(".")[0]} url={image.url}/>} index={index} />)}
            </div>
            {/* {selectedIndex >= 0 && <Modal images={images} selectedImg={images[selectedIndex]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} />} */}
            {/* <Modal images={images} selectedImg={images[selectedIndex]} selectedIndex={selectedIndex} setSelectedIndex={setSelectedIndex} /> */}
        </div>
    )


};

export default SimilarImageQueryResultList;