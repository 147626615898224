import React, { useEffect, useState } from 'react';
import './css/CollectionBatchImport.css'
import useApi from './useApi';
import Toast from '../components/elements/Toast'
import {apiCallResponseFeedback} from '../helper/ErrorHandler'
import Button from '../components/elements/Button'

function CollectionBatchImport({collectionId}) {
    // State variables to hold the S3 bucket and credentials
    const [bucket, setBucket] = useState('');
    const [accessKeyId, setAccessKeyId] = useState('');
    const [secretAccessKey, setSecretAccessKey] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [formReady, setFormReady] = useState(false);
    const restApi = useApi();


    useEffect(() => {
        if (bucket && accessKeyId && secretAccessKey) {
            setFormReady(true)
        } else {
            setFormReady(false)
        }
    }, [bucket, accessKeyId, secretAccessKey])

    // Function to handle the start button click
    const handleSubmit = (event) => {
        event.preventDefault()
        setIsLoading(true)
        // Send a POST request to the API endpoint with the S3 bucket and credentials data
        restApi.requestBatchImport(bucket, accessKeyId, secretAccessKey, collectionId)
        .then(response => apiCallResponseFeedback(response))
        .then(data => {
            Toast.fire({
                icon: 'success',
                title: 'Job submitted',
                // text: data.message
            })
            const jobId = data.jobId

            
            // Redirect to job page with jobId with react
            
        })
        .catch(error => {
            // Handle any errors
            Toast.fire({
                icon: 'error',
                title: 'Job not submitted',
                text: error.message
            })
        }).finally(() => {
            setIsLoading(false)
        });
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label htmlFor="s3_bucket">S3 Bucket Name:</label>
                <input autoComplete='off'  type="text" className="form-control" id="s3_bucket" placeholder="Enter Bucket Name" onChange={event => setBucket(event.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="aws_access_key_id">AWS Access Key ID:</label>
                <input autoComplete='off' type="text" className="form-control" id="aws_access_key_id" placeholder="Enter Access Key Id" onChange={event => setAccessKeyId(event.target.value)} />
            </div>
            <div className="form-group">
                <label htmlFor="aws_secret_access_key">AWS Secret Key:</label>
                <input autoComplete='off' type="text" className="form-control" id="aws_secret_access_key" placeholder="Enter Secret Access Key" onChange={event => setSecretAccessKey(event.target.value)} />
            </div>
            <Button disabled={isLoading || !formReady} type="submit">
                {!isLoading && <span>Start Import</span>}
                {isLoading && <span>Submitting</span>}
            </Button>
        </form>
    );
}

export default CollectionBatchImport;