import React, { useEffect, useState } from 'react';
import Card from '../components/Card';
import useApi from '../components/useApi';
import Select from 'react-select';
import Button from '../components/elements/Button';
import { apiCallResponseFeedback } from '../helper/ErrorHandler';
import { useNavigate } from "react-router-dom";
import Toast from '../components/elements/Toast';

const CollectionCreateView = () => {
    const [collectionName, setCollectionName] = useState('');
    const [modelMethod, setModelMethod] = useState('');
    const [objectType, setObjectType] = useState('');
    const [objectTypeSelected, setObjectTypeSelected] = useState(false);
    const [description, setDescription] = useState('');
    let navigate = useNavigate();
    const restApi = useApi();


    // Remove is-invalid class from field when user starts typing

    const handleChangeCollectionName = (event) => {
        setCollectionName(event.target.value);
        event.target.classList.remove("is-invalid")
    }

    const markAllRequiredFields = () => {
        if (collectionName === '') {
            document.getElementById('collectionName').classList.add('is-invalid');
        }
        if (modelMethod === '') {
            document.getElementById('modelMethod').classList.add('is-invalid');
        }
        if (objectType === '') {
            document.getElementById('objectType').classList.add('is-invalid');
        }
    }
        

    function handleSubmit(event) {
        event.preventDefault();
        // Check if all fields are filled
        if (collectionName === '' || modelMethod === '' || objectType === '') {
            markAllRequiredFields()
            return;
        }
        restApi.createCollection(collectionName)
            .then(response => apiCallResponseFeedback(response))
            .then((result) => {
                console.log(result)
                let collection_id = result.collection._id
                navigate(`/collections/${collection_id}`)
            })
            .catch(error => {
                // Show Toast
                Toast.fire({
                    icon: 'error',
                    title: 'Collection not created',
                    text: error.message
                })
            })
    }

    const modelMethodOptions = [
        { value: 'gid', label: 'Global Image Descriptor' },
    ]

    const objectTypeOptions = [
        { value: 'image', label: 'Vectorize Images' },
        { value: 'video', label: 'Vectorize Videos', disabled: true},
        { value: 'audio', label: 'Vectorize Audio', disabled: true },
        { value: 'text', label: 'Vectorize Text', disabled: true },
        { value: 'text_qna', label: 'Question and Answer', disabled: true },
    ]

    const ObjectTypeSelection = <Select
        options={objectTypeOptions}
        isOptionDisabled={(option) => option.disabled}
        // defaultValue={objectTypeOptions[0]}
        onChange={setObjectType}>
    </Select>

    const ModelMethodSelection = <Select
        options={modelMethodOptions}
        // defaultValue={modelMethodOptions[0]}
        onChange={setModelMethod}>
    </Select>



    return (
        <div className="content-wrapper">
            <div className='content-header'>
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-12">
                            <h1>Create a new collection</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                <div className="container-fluid">
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-lg-6">
                                <Card
                                    title="Create new Collection"
                                    cardClasses='card-primary'
                                    >
                                        <div className="form-group">
                                            <label htmlFor="collectionName">Collection Name</label>
                                        <input type="text" className="form-control" id="collectionName" placeholder="Enter Collection Name" onChange={handleChangeCollectionName} />
                                        </div>


                                </Card>
                            </div>
                            <div className="col-lg-6">
                                <Card
                                    title="Collection Options"
                                    cardClasses='card-secondary'
                                >
                                    <div className="form-group">
                                        <label htmlFor="objectType">What do you want to do?</label>
                                        {ObjectTypeSelection}
                                    </div>
                                    {objectType &&
                                        <div className="form-group">
                                        <label>Model Method</label>
                                        {ModelMethodSelection}
                                        </div>
                                    }

                                    {modelMethod &&
                                        <Button>Create</Button>
                                    }

                                </Card>
                            </div>
                        </div>
                    </form>
                </div>


                {/* <form onSubmit={handleSubmit}>
                    <label>
                        Collection name:
                        <input type="text" value={collectionName} onChange={} />
                    </label>
                    <button type="submit">Create collection</button>
                </form> */}
            </div>
        </div>
    );

}

export default CollectionCreateView;