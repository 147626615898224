import Button from "./elements/Button"
import React, { useState } from 'react';
import CollectionBatchImport from '../components/CollectionBatchImport';

function CollectionBatchImportSelector({collectionId}) {
    const [selection, setSelection] = useState('');

    const renderComponent = () => {
        switch (selection) {
            case 'S3':
                return <CollectionBatchImport collectionId={collectionId}/>;
            case 'GCS':
                return <GCSComponent />;
            case 'Upload':
                return <UploadComponent />;
            default:
                return (
                    <>
                        <Button onClick={() => setSelection('S3')}>AWS S3 Bucket</Button>
                        <Button disabled onClick={() => setSelection('GCS')}>GCS Bucket</Button>
                        <Button disabled onClick={() => setSelection('Upload')}>Upload files</Button>
                    </>
                );
        }
    };

    // Function to handle the back button click
    const handleBackClick = () => {
        setSelection('');
    };

    // Render the component
    return (
        <>
            {renderComponent()}
            {selection && (
                <Button className="btn btn-block btn-danger mt-5" onClick={handleBackClick}>Cancel</Button>
            )}
        </>
    );
}

function GCSComponent() {
    return <div>GCS Component</div>;
}

function UploadComponent() {
    return <div>Upload Component</div>;
}

export default CollectionBatchImportSelector;