import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './CollectionsList.css';
import { useAuth0 } from "@auth0/auth0-react";
import useApi from './components/useApi';
import Card from './components/Card';

const CollectionsList = () => {
  const [collections, setCollections] = useState([]);
  const { user, isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
  const restApi = useApi();

  useEffect(() => {
    restApi.getCollections()
    .then(response => response.json())
    .then(response => {
      setCollections(response.collections)
    })
  }, [isLoading]);

  const handleDelete = (collection_id) => {
    restApi.deleteCollection(collection_id)
    .then(response => response.json())
    .then(response => {
      console.log(response)
    })
  }

  return (
    <Card title="Collections" cardClasses="" cardBodyClasses="p-0">
      <table className="table table-striped projects">
        <thead>
          <tr>
            <th style={{width: '1%'}}>
              #
            </th>
            <th style={{width: '20%'}}>
              Collection Name
            </th>
            <th style={{ width: '10%' }}>
              No. Objects
            </th>
            <th style={{width: '39%'}}>
              Description
            </th>
            <th style={{ width: '30%' }}>
              {/* Actions */}
            </th>
          </tr>
        </thead>
        <tbody>
          {collections.map((collection) => (
            <tr>
              <td>
                #
              </td>
              <td>
                {collection.name}
                <br />
                <small>Created: {collection.created_at}</small>
              </td>
              <td>
                {collection.no_objects || 0}
              </td>
              <td>
                {collection.description || "Description not available"}
              </td>
              <td className="project-actions text-right">
                <Link className="btn btn-primary btn-sm" to={`/collections/${collection._id}`}>
                  <i className="fas fa-folder">
                  </i>
                  &nbsp; View
                </Link>
                &nbsp;
                <Link className="btn btn-info btn-sm" to={`/collections/${collection._id}/edit`}>
                  <i className="fas fa-pencil-alt">
                  </i>
                  &nbsp; Edit
                </Link>
                &nbsp;
                <button className="btn btn-danger btn-sm" onClick={() => handleDelete(collection._id)}>
                  <i className="fas fa-trash">
                  </i>
                  &nbsp; Delete
                </button>

              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Card>
  );
};

export default CollectionsList;