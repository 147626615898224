import React, { useState } from 'react';
import SelectCollections from './SelectCollections';

const QueryView = () => {
  const [image, setImage] = useState(null);
  const [similarImages, setSimilarImages] = useState([]);
  const [collectionId, setCollectionId] = useState([]);

  const handleChange = (event) => {
    setImage(event.target.files[0]);
  }

  const handleCollectionSelect = (collection_id) => {
    setCollectionId(collection_id)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    
    // Send the image to the API endpoint using axios or fetch
  }



  const sendImage = (event) => {
    event.preventDefault()
    const data = new FormData();

    data.append('image', image);
    data.append('collection_id', collectionId)
  
    const options = {
      method: 'POST',
      body: data,
    };
    fetch('http://localhost:3001/collections/query', options)
      .then((response) => response.json())
      .then((result) => {
        console.log(result)
        setSimilarImages(result.result)
        // handle the response from the server
      })
      .catch((error) => {
        // handle the error
      });
  }

  return (
    <form onSubmit={sendImage}>
      <input type="file" name="image" onChange={handleChange} />
      <SelectCollections onSelect={handleCollectionSelect}></SelectCollections>
      <button type="submit">Upload</button>
      {similarImages.length > 0 && (
        <ul>
          {similarImages.map((image) => (
            <li key={image.id}>{image.name}</li>
          ))}
        </ul>
      )}
    </form>
  );
}

export default QueryView;